const courseExpiration = [
  {
    id: 1,
    stdId: 'ST123',
    name: 'Cristiano Ronaldo',
    expirationDate: '29 Oct, 2020'
  },
  
  {
    id: 2,
    stdId: 'ST123',
    name: 'Cristiano Ronaldo',
    expirationDate: '29 Oct, 2020'
  },
  {
    id: 3,
    stdId: 'ST123',
    name: 'Cristiano Ronaldo',
    expirationDate: '1 Nov, 2020'
  },
  
  {
    id: 4,
    stdId: 'ST123',
    name: 'Cristiano Ronaldo',
    expirationDate: '2 Nov, 2020'
  },
  {
    id: 5,
    stdId: 'ST123',
    name: 'Cristiano Ronaldo',
    expirationDate: '9 Nov, 2020'
  },

];


const todayTasks = [];
for (let i = 0; i < 5; i++) {
    todayTasks.push({
        id: i,
        taskId: 'ST123'+i,
        taskName: 'Cristiano Ronaldo',
        createdBy: 'Kazi Shahin',
        createdAt: '29 Sep, 2020',
        branch: 'Dhanmondi',
        status: 'Incomplete'
    });
}

const trainersAvailability = [];
for (let i = 0; i < 5; i++) {
    trainersAvailability.push({
        id: i,
        trainerId: 'TR121'+i,
        trainerName: 'Cristiano Ronaldo',
        branch: 'Dhanmondi',
        workingHour: '8 Hours',
        availability: 'From 8:00 am to 4:00 pm',
        status: 'Available'
    });
}

const graphData = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [8, 6, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
};

const taskLists = [
  {id: 1, label: 'Name of task', done: true},
  {id: 2, label: 'Name of task', done: true},
  {id: 3, label: 'Name of task', done: false},
  {id: 4, label: 'Name of task', done: false},
  {id: 5, label: 'Name of task', done: true},
  {id: 6, label: 'Name of task', done: true},
  {id: 7, label: 'Name of task', done: false},
];



const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }

export default {
    courseExpiration,
    todayTasks,
    trainersAvailability,
    graph: {
        data: graphData,
        options: options
    },
    taskLists,
    exam: courseExpiration
}