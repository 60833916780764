<template>
  <Card>
    <CardHeader class="flex justify-between" color="#514b99">
      <div class="p-3 title flex">
        <md-icon class="text-white">menu_book</md-icon>
        <h3 class="pl-2 text-white text-uppercase">{{ $t(label) }}</h3>
      </div>
      <div class="p-3 action">
        <md-icon class="text-white">remove_red_eye</md-icon>
      </div>
    </CardHeader>
    <CardBody class="p-3">
      <Graph
        :graph-data="graphData"
        :options="options"
       />
    </CardBody>
  </Card>
</template>

<script>
import { Card, CardHeader, CardBody } from "@/components";
import Graph from "@/components/atom/Graph";
import data from "@/data/portal/data"
export default {
  name: "Event-Component",
  components: {
    Card,
    Graph,
    CardHeader,
    CardBody,
  },
  data() {
    return {
      graphData: data.graph.data,
      options: data.graph.options
    };
  },
  props: {
    label: {
      type: String
    }
  }
};
</script>
