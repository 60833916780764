<template>
  <div class="p-6" style="max-width: 860px">
      <DialogTitle :label="content.title" align="center" />
      <div class="text-center text-lg w-75p m-auto line-height-27 font-semibold my-16">
          {{ content.description }}
      </div>
      <div class="flex justify-center mt-10">
        <md-button @click="dialog(false)" class="outline-gray-400 rounded">Exit</md-button>
        <md-button class="text-white bg-green rounded" @click="$emit('on-mark-complete', content.index)">Mark is as complete</md-button>
      </div>
  </div>
</template>

<script>
import { DialogTitle } from "@/components";
import { mapMutations } from 'vuex';
export default {
    components: {
        DialogTitle
    },
    props: {
        content: {
            type: Object
        }
    },
    methods: mapMutations({
      dialog: "setShowDialog",
    })
}
</script>

<style>

</style>