<template>
  <div>
    <WelcomeCard @on-signout="onSignOut" label="portal.tr-welcome" />

    <div class="md-layout my-6 md-gutter">
      <div
        class="md-layout-item md-size-25 md-xsmall-size-100 md-small-size-50 md-medium-size-40 mb-5"
      >
        <TaskList
          label="portal.taskList"
          :tasks="taskList.data"
          :remaining="taskList.remaining"
          @on-change-status="onChangeStatus"
        />
      </div>
      <div
        class="md-layout-item md-size-75 md-xsmall-size-100 md-small-size-50 md-medium-size-60"
      >
        <StudentParticipatonGraph label="portal.graph" title="Graph component" />
      </div>
    </div>

    <div class="md-layout mb-5 md-gutter">
      <div
        class="md-layout-item  md-size-25 md-xsmall-size-100 md-small-size-35 md-medium-size-40 mb-5"
      >
        <EventCard label="portal.event" />
      </div>
      <div
        class="md-layout-item md-size-75 md-xsmall-size-100 md-small-size-65 md-medium-size-60"
      >
        <PortalCalendarCard @on-details="onDetails" />
      </div>
    </div>

    <div class="md-layout mb-5 md-gutter">
      <div class="md-layout-item md-small-size-100 mb-5">
        <PartalCard label="portal.expire">
          <SwitchComponent :source="courseExpiration" @on-switch="onSwitch"/>
          <template slot="table">
            <Table :items="courseExpiration">
              <template slot="row" slot-scope="{ item }" md-selectable="single">
                <md-table-cell md-label="STD_ID">{{ item.stdId }}</md-table-cell>
                <md-table-cell md-label="NAME">{{ item.name }}</md-table-cell>
                <md-table-cell md-label="EXPIRATION DATE">{{ item.expirationDate }}</md-table-cell>
                <md-table-cell>
                  <md-icon class="text-victoria text-sm">visibility</md-icon>
                </md-table-cell>
              </template>
            </Table>
          </template>
        </PartalCard>
      </div>
      <div class="md-layout-item md-small-size-100">
        <PartalCard label="portal.exam">
          <SwitchComponent :source="exam" @on-switch="onSwitch"/>
          <template slot="table">
            <Table :items="exam">
              <template slot="row" slot-scope="{ item }" md-selectable="single">
                <md-table-cell md-label="STD_ID">{{ item.stdId }}</md-table-cell>
                <md-table-cell md-label="NAME">{{ item.name }}</md-table-cell>
                <md-table-cell md-label="EXAM DATE">{{ item.expirationDate }}</md-table-cell>
                <md-table-cell>
                  <md-icon class="text-victoria text-sm">visibility</md-icon>
                </md-table-cell>
              </template>
            </Table>
          </template>
        </PartalCard>
      </div>
    </div>

    <div class="md-layout mb-5 md-gutter">
      <div class="md-layout-item md-small-size-100">
        <PartalCard color="#00df05" label="portal.taskAssignBySuperAdmin">
          <template slot="table">
            <Table :items="todayTasks">
              <template slot="row" slot-scope="{ item }">
                <md-table-cell md-label="TASK_ID">{{ item.taskId }}</md-table-cell>
                <md-table-cell md-label="TASK NAME">{{ item.taskName }}</md-table-cell>
                <md-table-cell md-label="CREATED BY">{{ item.createdBy }}</md-table-cell>
                <md-table-cell md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
                <md-table-cell md-label="BRANCH">{{ item.branch }}</md-table-cell>
                <md-table-cell md-label="STATUS" class="text-danger">{{ item.status }}</md-table-cell>
              </template>
            </Table>
          </template>
        </PartalCard>
      </div>
    </div>

    <div class="md-layout mb-5 md-gutter">
      <div class="md-layout-item md-small-size-100">
        <PartalCard label="portal.taskAssignForTrainer">
          <template slot="table">
            <Table :items="todayTasks">
              <template slot="row" slot-scope="{ item }">
                <md-table-cell md-label="TASK_ID">{{ item.taskId }}</md-table-cell>
                <md-table-cell md-label="TASK NAME">{{ item.taskName }}</md-table-cell>
                <md-table-cell md-label="CREATED BY">{{ item.createdBy }}</md-table-cell>
                <md-table-cell md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
                <md-table-cell md-label="BRANCH">{{ item.branch }}</md-table-cell>
                <md-table-cell md-label="STATUS" class="text-danger">{{ item.status }}</md-table-cell>
              </template>
            </Table>
          </template>
        </PartalCard>
      </div>
    </div>

    <div class="md-layout mb-5 md-gutter">
      <div class="md-layout-item md-small-size-100">
        <PartalCard label="portal.availability">
          <template slot="table">
            <Table :items="trainersAvailability">
              <template slot="row" slot-scope="{ item }">
                <md-table-cell md-label="ID">{{ item.trainerId }}</md-table-cell>
                <md-table-cell md-label="TRAINER NAME">{{ item.trainerName }}</md-table-cell>
                <md-table-cell md-label="BRANCH">{{ item.branch }}</md-table-cell>
                <md-table-cell md-label="WORKING HOUR">{{ item.workingHour }}</md-table-cell>
                <md-table-cell md-label="AVAILABILITY">{{ item.availability }}</md-table-cell>
                <md-table-cell md-label="STATUS" class="text-green">{{ item.status }}</md-table-cell>
              </template>
            </Table>
          </template>
        </PartalCard>
      </div>
    </div>
    <Dialog>
      <components :is="component" :content="content" @on-mark-complete="onMarkComplete"></components>
    </Dialog>
  </div>
</template>

<script>
import {
  Table,
  Dialog,
  TaskList,
  EventCard,
  WelcomeCard,
  SwitchComponent
} from "@/components";
import PortalCalendarCard from "@/components/molecule/PortalCalendarCard";
import StudentParticipatonGraph from "@/components/molecule/portal/StudentParticipatonGraph";
import PartalCard from "@/components/molecule/PartalCard";
import SingOut from "@/components/molecule/SignOut";
import CalendarDetails from "@/components/molecule/portal/CalendarDetails";
import TaskDetails from "@/components/molecule/portal/TaskDetails";
import data from "@/data/portal/data";
import { mapMutations } from "vuex";
export default {
  name: "Home",
  components: {
    Table,
    Dialog,
    SingOut,
    TaskList,
    EventCard,
    PartalCard,
    TaskDetails,
    WelcomeCard,
    CalendarDetails,
    SwitchComponent,
    PortalCalendarCard,
    StudentParticipatonGraph 
  },
  data() {
    return {
      taskLists: data.taskLists,
      component: 'SingOut',
      content: {},
      todayTasks: data.todayTasks,
      trainersAvailability: data.trainersAvailability,
      date: new Date('29 Oct, 2020')
    }
  },
  computed: {
    courseExpiration() {
      return this.onDataFilter(data.courseExpiration)
    },
    exam() {
      return this.onDataFilter(data.exam);
    },
    taskList() {
      return {
          data: this.taskLists,
          remaining: this.taskLists.filter(item => !item.done).length
        }
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onSignOut(content) {
      this.component = 'SingOut';
      this.content = content;
      this.dialog(true);
    },
    onDetails() {
      this.component = 'CalendarDetails';
      this.content = {}
      this.dialog(true);
    },
    onSwitch({item}) {
      let date;
      if(item.name !== 'Today') {
        date = new Date('1 Nov, 2020');
      }else {
        date = new Date('29 Oct, 2020');
      }
      this.date = date;
    },
    onDataFilter(source) {
      return source.filter(item => new Date(item.expirationDate) > this.date)
    },
    onChangeStatus(index) {
      if(!this.taskLists[index].done) {
        this.component = 'TaskDetails';
        this.content = {
          title: 'Complete 5 PTE Practice On Speaking',
          description: 'Complete 5 PTE practice on speaking secition, Please give extra effect on read aloud and repeat section cause the posibility of coming to next Mock Exam',
          index : index
        }
        this.dialog(true);
      }
      
    },
    onMarkComplete(index) {
      this.dialog(false);
      this.taskLists[index].done = !this.taskLists[index].done;
    }
  }
};
</script>
