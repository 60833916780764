<template>
  <Card>
    <CardHeader class="flex justify-between" color="#514b99">
      <div class="p-3 title flex">
        <md-icon class="text-white">healing</md-icon>
        <h3 class="pl-2 text-white text-uppercase">Class </h3>
      </div>
      <div class="p-3 action">
        <md-icon class="text-white">remove_red_eye</md-icon>
      </div>
    </CardHeader>
    <CardBody class="p-3">
      <div class="px-2 py-3">
        <div class="flex flex-wrap justify-between justify-sm-center justify-xs-center">
          <div class="mx-2 my-1">
            <span class="inline-block bollet bollet-success"></span> Branch Class
            <span class="ml-3 inline-block bollet bollet-danger"></span> Online Class
          </div>
          <div class="mx-2 my-1">
            <p class="text-primary text-uppercase font-bold text-lg">{{ currentMonthAndYear }}</p>
          </div>
          <div class="mx-2 my-1">
            <div class="flex align-center">
              <md-icon @click.native="calenderView = true" class="pointer">date_range</md-icon>
              <md-switch
                v-model="calenderView"
                class="px-2 m-0"
              ></md-switch>
              <md-icon @click.native="calenderView = false" class="pointer">format_list_bulleted</md-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <Calendar v-if="calenderView" days classObject="mini" @on-click="onClickCell">
          <template v-slot="{day}">
            <div class="calendar-cell bottom-1">
              <span v-if="branchClassDays.includes(day)" class="inline-block bollet-xs bollet-success"></span>
              <span v-if="onlineClassDays.includes(day)" class="inline-block bollet-xs bollet-danger"></span>
            </div>
          </template>
        </Calendar>
        <Table v-else :items="courseExpiration">
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="STD_ID">{{ item.stdId }}</md-table-cell>
            <md-table-cell md-label="NAME">{{ item.name }}</md-table-cell>
            <md-table-cell md-label="EXAM DATE">{{ item.expirationDate }}</md-table-cell>
            <md-table-cell>
              <md-icon class="text-victoria text-sm">visibility</md-icon>
            </md-table-cell>
          </template>
        </Table>
      </div>
    </CardBody>
  </Card>
</template>

<script>
import { Card, CardHeader, CardBody, Table } from "@/components";
import Calendar from "@/components/atom/Calendar";
import data from "@/data/portal/data"
export default {
  name: "Event-Component",
  components: {
    Card,
    Table,
    CardHeader,
    CardBody,
    Calendar,
  },
  data() {
    return {
      currentMonthAndYear: new Date().toLocaleString('default', {month: "short", year: 'numeric'}),
      calenderView: true,
      courseExpiration: data.courseExpiration,
      branchClassDays: [7, 8, 11, 16, 24],
      onlineClassDays: [9, 11, 14, 20, 24, 29],
    };
  },
  methods: {
    onClickCell(day) {
      let events = [...this.branchClassDays, ...this.onlineClassDays];
      if(events.includes(day)) {
         this.$emit('on-details', day);
      }
    },
  }
};
</script>

<style lang="scss">
.table {
  width: 100%;
}
.horizontal-divider {
  width: 1px;
  height: 80px;
  background: rgb(206, 206, 206);
}

</style>
