<template>
  <Card>
    <CardHeader class="flex justify-between" :color="color">
      <div class="p-3 title flex">
        <md-icon class="text-white">healing</md-icon>
        <h3 class="pl-2 text-white text-uppercase"> {{ $t(label) }}</h3>
      </div>
      <div class="p-3 action">
        <md-icon class="text-white">remove_red_eye</md-icon>
      </div>
    </CardHeader>
    <CardBody>
      <div class="flex justify-end">
        <slot></slot>
      </div>
      <slot name="table"></slot>
    </CardBody>
  </Card>
</template>

<script>
import { Card, CardHeader, CardBody } from "@/components";
export default {
  name: "Todo-List",
  components: {
    Card,
    CardHeader,
    CardBody,
  },
  props: {
    label: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: () => '#514b99'
    }
  },
  methods: {
    test() {
      alert('clicked ....');
    }
  }
};
</script>

<style></style>
