<template>
  <div class="text-center p-10" style="width: 760px">
      <img :src="src" alt="">
      <p class="text-uppercase font-semibold text-lg my-6">{{ text }}</p>
      <p v-if="message" class="text-uppercase text-2xl font-bold mb-10">You have <span class="text-danger">4 tasks</span> to complete</p>

      <div>
          <div v-if="cancelButton" 
          class="outline-gray-400 text-uppercase my-4 py-2 px-3 rounded inline-block pointer"
          @click="dialog(false)"
          >Cancel Sing Out</div>
            <br>
          <div 
            class="bg-green py-2 px-8 inline-block rounded text-white text-uppercase pointer"
            @click="onSignOut">Foorce Sign Out</div>
      </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
    props: {
        content: {
            type: Object
        }
    },
    computed: {
        src() {
            return this.content.src
        },
        text() {
            return this.content.text
        },
        message() {
            return this.content.message
        },
        cancelButton() {
            return this.content.cancelButton
        }
    },
     methods: { 
        ...mapMutations({
            dialog: "setShowDialog",
        }),
        onSignOut() {
            // session wiill be close
            this.$router.push('/login')
        }
     }
}
</script>

<style>

</style>