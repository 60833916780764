<template>
    <div class="p-6" style="width: 650px">
        <DialogTitle style="margin-left: -10px; margin-right: -10px" label="class" align="center" />
        <Content class="my-3" label="Selected Date" text="14 Jun, 2019" />
        <Content class="my-3" label="Time" text="6:00pm" />
        <Content class="my-3" label="Class Name" text="Moonlight" />
        <Content class="my-3" label="Class Type" text="Branch Class" textColor="text-green" />
        <Content class="my-3" label="Venue" text="Dhanmondi Branch Office"/>
        <Content class="my-3" label="Group" text="Group A, Group B"/>
        <Content class="my-3" label="Class Description" textColor="text-base capitalize" text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make "/>
        
        <div class="flex justify-center">
            <md-button class="outline-gray-400 text-uppercase rounded" @click="dialog(false)">Back</md-button>
        </div> 
    </div>
</template>

<script>
import { DialogTitle } from "@/components";
import Content from "@/components/atom/Content";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
    Content
  },
  props: {
      content: {
          type: Object
      }
  },
  methods: mapMutations({
      dialog: "setShowDialog",
  })
}
</script>